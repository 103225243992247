export const constants = {
  REGISTER_REQUEST: "REPORT_REGISTER_REQUEST",
  REGISTER_SUCCESS: "REPORT_REGISTER_SUCCESS",
  REGISTER_FAILURE: "REPORT_REGISTER_FAILURE",

  DOCUMENT_REQUEST: "REPORT_DOCUMENT_REQUEST",
  DOCUMENT_SUCCESS: "REPORT_DOCUMENT_SUCCESS",
  DOCUMENT_FAILURE: "REPORT_DOCUMENT_FAILURE",

  ACTION_REQUEST: "REPORT_ACTION_REQUEST",
  ACTION_SUCCESS: "REPORT_ACTION_SUCCESS",
  ACTION_FAILURE: "REPORT_ACTION_FAILURE",

  DOWNLOAD_PDF_REQUEST: "DOWNLOAD_PDF_REQUEST",
  DOWNLOAD_PDF_SUCCESS: "DOWNLOAD_PDF_SUCCESS",
  DOWNLOAD_PDF_FAILURE: "DOWNLOAD_PDF_FAILURE",

  // chat discarded
  FETCH_MESSAGES_REQUEST: "FETCH_MESSAGES_REQUEST",
  FETCH_MESSAGES_SUCCESS: "FETCH_MESSAGES_SUCCESS",
  FETCH_MESSAGES_FAILURE: "FETCH_MESSAGES_FAILURE",

  SEND_MESSAGE_REQUEST: "SEND_MESSAGE_REQUEST",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",

  DOWNLOAD_HISTORY_CHAT_DISCARDED_REQUEST:
    "DOWNLOAD_HISTORY_CHAT_DISCARDED_REQUEST",
  DOWNLOAD_HISTORY_CHAT_DISCARDED_SUCCESS:
    "DOWNLOAD_HISTORY_CHAT_DISCARDED_SUCCESS",
  DOWNLOAD_HISTORY_CHAT_DISCARDED_FAILURE:
    "DOWNLOAD_HISTORY_CHAT_DISCARDED_FAILURE",

  DOWNLOAD_PDF_MESSAGE_DISCARDED_REQUEST:
    "DOWNLOAD_PDF_MESSAGE_DISCARDED_REQUEST",
  DOWNLOAD_PDF_MESSAGE_DISCARDED_SUCCESS:
    "DOWNLOAD_PDF_MESSAGE_DISCARDED_SUCCESS",
  DOWNLOAD_PDF_MESSAGE_DISCARDED_FAILURE:
    "DOWNLOAD_PDF_MESSAGE_DISCARDED_FAILURE",
};
