import { combineReducers } from "redux";
import { reducer as user } from "./user/reducer";
import { reducer as report } from "./report/reducer";
import { reducer as comuni } from "./comuni/reducer";
import { messageReducer as messages } from "./report/reducer";

const rootReducer = combineReducers({
  comuni,
  report,
  messages,
});

export default rootReducer;
