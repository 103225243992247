import { constants } from "./constants";
import { service } from "./service";

export const actions = {
  logout,
  register,
  requestPasswordReset,
  passwordReset,
  login,
  socialLogin,
  loadReports,
  loadReport,
  createReport,
  document,
  payed,
  confirmDocument,
  uploadDocument,
  uploadDocumentIdCard,
  download,
  digitalSign,
  arubaSign,
  arubaRegister,
  documentPreventivo,
  confirmDocumentPreventivo,
  payPaypalYouSign,
  payPaypalOwnSign,
  validationMail,
  EmptyRegister,
  userIdTokeCalendly,
  PartnerCustomerVerification,
  getMessagesChatDiscarded,
  sendMessageChatDiscarded,
  sendFileChatDiscarded,
  downloadFileChatDiscarded,
  downloadHistoryChatDiscarded,
};

function logout() {
  const data = {
    action: "LOGOUT",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function register(
  name,
  email,
  password,
  password_confirmation,
  privacy,
  terms,
  id,
  token,
  additional_field
) {
  const data = {
    name: name,
    email: email,
    password: password,
    password_confirmation: password_confirmation,
    privacy: privacy,
    terms: terms,
    action: "REGISTER",
    id: id,
    token: token,
    additional_field: additional_field,
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data, registrationSuccess: true };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function EmptyRegister() {
  const data = {
    action: "TO_EMPTY_REGISTRATION_OBJECT",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data, registrationSuccess: true };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function validationMail(token, email) {
  const data = {
    token: token,
    email: email,
    action: "CHECK_LOGIN_VERIFICATION",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function requestPasswordReset(email) {
  const data = {
    email: email,
    action: "REQUEST_PASSWORD_RESET",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function passwordReset(token, password, password_confirmation) {
  const data = {
    token: token,
    password: password,
    password_confirmation: password_confirmation,
    action: "PASSWORD_RESET",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function login(email, name, password, fb_token, provider, provider_id) {
  const data = {
    email: email,
    name: name,
    password: password,
    fb_token: fb_token,
    action: "LOGIN",
    provider: provider,
    provider_id: provider_id,
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function socialLogin(email, name, provider, provider_id) {
  const data = {
    email: email,
    name: name,
    action: "SOCIAL_LOGIN",
    provider: provider,
    provider_id: provider_id,
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function loadReports() {
  const data = {
    action: "LOAD_REPORTS",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function loadReport(reportId, documentType, documentRecipe) {
  const data = {
    action: "LOAD_REPORTS",
    reportId: reportId,
    documentType: documentType,
    documentRecipe: documentRecipe,
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function digitalSign(digitalSign, reportId) {
  const data = {
    reportId: reportId,
    digitalSign: digitalSign,
    action: "DIGITAL_SIGN",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function document(documentId, type, reportId = null) {
  const data = {
    type: type,
    documentId: documentId,
    action: "SET_REPORT_DOCUMENT_ID",
    reportId: reportId,
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function documentPreventivo(documentId, type, uuid) {
  const data = {
    type: type,
    documentId: documentId,
    action: "END_FORM_PREVENTIVO",
    uuid: uuid,
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function confirmDocumentPreventivo(uuid) {
  const data = {
    uuid: uuid,
    action: "CONFIRM_PREVENTIVO",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function uploadDocument(reportId, file, fileName) {
  const data = {
    reportId: reportId,
    action: "UPLOAD_REPORT_DOCUMENT",
    file: file,
    fileName: fileName,
  };

  return (dispatch) => {
    dispatch(request());

    service.upload(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

// action caricamento documento di riconoscimento

function uploadDocumentIdCard(reportId, files) {
  const data = {
    reportId: reportId,
    action: "UPLOAD_REPORT_ID_CARD",
    files: files,
    fileName: "ciao",
  };

  return (dispatch) => {
    dispatch(request());

    service.uploadIdDocument(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function confirmDocument(reportId) {
  const data = {
    reportId: reportId,
    action: "CONFIRM_REPORT_DOCUMENT",
  };

  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function payed(order, reportId) {
  const data = {
    reportId: reportId,
    order: order,
    action: "REPORT_PAYED",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function payPaypalYouSign(order, reportId, skip) {
  const data = {
    reportId: reportId,
    order: order,
    action: "REPORT_PAYING_DIGITAL_SIGN",
    skip: skip,
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
        console.log(data);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function payPaypalOwnSign(order, reportId, skip) {
  const data = {
    reportId: reportId,
    order: order,
    action: "REPORT_PAYING_OWN_SIGN",
    skip: skip,
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function download(reportId, documentType) {
  const data = {
    reportId: reportId,
    documentType: documentType,
    //action: "DOWNLOAD_DOCUMENT",
  };
  return (dispatch) => {
    dispatch(request());

    /*
    service.download(data).then(
        (response) => {
          console.log(response.data);
            // Creare un oggetto URL per il blob ricevuto
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'filename.pdf'); // Specifica il nome del file
            document.body.appendChild(link);
            link.click();
            dispatch({ type: constants.DOWNLOAD_PDF_SUCCESS });
        }).catch((error) => {
        dispatch({ type: constants.DOWNLOAD_PDF_FAILURE, error: error });
    });*/

    /*(data) => {
            console.log(data);
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'filename.pdf'); // Specifica il nome del file
            document.body.appendChild(link);
            link.click();
            console.log('success');
            dispatch(success(data));
        },
        (error) => {
            console.log('error');
            dispatch(failure(error));
        }
    );*/
    /*
    service.download(data).then((response) => {
          // Creare un oggetto URL per il blob ricevuto
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'filename.pdf'); // Specifica il nome del file
          document.body.appendChild(link);
          link.click();
          dispatch({ type: "SAVE" });
          console.log("success");
      }).catch((error) => {
          console.error('Errore durante il download del PDF:' , error);
          dispatch({ type: constants.DOWNLOAD_PDF_FAILURE, error: error });
      });*/
    service.download(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.DOWNLOAD_PDF_REQUEST };
  }
  function success(data) {
    return { type: constants.DOWNLOAD_PDF_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.DOCUMENT_FAILURE, error };
  }
}

function createReport(type) {
  const data = {
    type: type,
    action: "CRETATE_REPORT",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function arubaSign(login, password, otp, reportId) {
  const data = {
    reportId: reportId,
    login: login,
    password: password,
    otp: otp,
    action: "ARUBA_SIGN",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function arubaRegister(userData, reportId) {
  const data = {
    reportId: reportId,
    ...userData,
    action: "ARUBA_REGISTER",
  };
  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function userIdTokeCalendly() {
  const data = {
    action: "SET_USER_ID_FOR_CALENDLY",
  };

  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}

function getMessagesChatDiscarded(customer_id, reportId) {
  const data = {
    action: "FETCH_MESSAGES",
    customer_id: customer_id,
    denuncia_id: reportId,
  };

  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.FETCH_MESSAGES_REQUEST };
  }
  function success(data) {
    return { type: constants.FETCH_MESSAGES_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.FETCH_MESSAGES_FAILURE, error };
  }
}

function sendMessageChatDiscarded(customer_id, message, reportId, sender_type) {
  const data = {
    action: "SEND_MESSAGE",
    customer_id: customer_id,
    message: message,
    denuncia_id: reportId,
    sender_type: sender_type,
  };

  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
        console.log("risposta", data);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.SEND_MESSAGE_REQUEST };
  }
  function success(data) {
    return { type: constants.SEND_MESSAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.SEND_MESSAGE_FAILURE, error };
  }
}

function sendFileChatDiscarded(
  customer_id,
  attached,
  denuncia_id,
  sender_type
) {
  const data = {
    action: "SEND_FILE_CHAT",
    customer_id: customer_id,
    attached: attached,
    denuncia_id: denuncia_id,
    sender_type: sender_type,
  };

  return (dispatch) => {
    dispatch(request());

    service.uploadSendSingleFile(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.SEND_MESSAGE_REQUEST };
  }
  function success(data) {
    return { type: constants.SEND_MESSAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.SEND_MESSAGE_FAILURE, error };
  }
}

function downloadFileChatDiscarded(customer_id, message_id, sender_type) {
  const data = {
    action: "DOWNLOAD_FILE_CHAT",
    customer_id: customer_id,
    message_id: message_id,
    sender_type: sender_type,
  };

  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.DOWNLOAD_PDF_MESSAGE_DISCARDED_REQUEST };
  }
  function success(data) {
    return { type: constants.DOWNLOAD_PDF_MESSAGE_DISCARDED_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.DOWNLOAD_PDF_MESSAGE_DISCARDED_FAILURE, error };
  }
}

function downloadHistoryChatDiscarded(denuncia_id) {
  const data = {
    action: "DOWNLOAD_HISTORY_CHAT",
    denuncia_id: denuncia_id,
  };

  return (dispatch) => {
    dispatch(request());

    service.doAction(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.DOWNLOAD_HISTORY_CHAT_DISCARDED_REQUEST };
  }
  function success(data) {
    return { type: constants.DOWNLOAD_HISTORY_CHAT_DISCARDED_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.DOWNLOAD_HISTORY_CHAT_DISCARDED_FAILURE, error };
  }
}

export function PartnerCustomerVerification(id, token) {
  const data = {
    id: id,
    token: token,
    action: "REGISTRATION_CHECK",
  };
  return (dispatch) => {
    dispatch(request());

    return service.doAction(data).then(
      (data) => {
        dispatch(success(data));
        return data;
      },
      (error) => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return { type: constants.ACTION_REQUEST };
  }
  function success(data) {
    return { type: constants.ACTION_SUCCESS, data, registrationSuccess: true };
  }
  function failure(error) {
    return { type: constants.ACTION_FAILURE, error };
  }
}
