import React, { useEffect } from "react";
import "assets/components/Blog.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid, Typography } from "@mui/material";
import CardBlog from "./CardBlog";
import { useState } from "react";
import BlogDetailArticle from "./BlogDetailArticle";

const BlogContainer = (props) => {
  const [articles, setArticles] = useState([]);
  const [isDetail, setIsDetail] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState({});

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_ENDPOINT + "/blog"
        );

        if (!response.ok) {
          throw new Error("Errore nella richiesta API");
        }
        const data = await response.json();
        setArticles(data.data.reverse());
      } catch (error) {
        console.error("Errore durante il recupero dell'HTML:", error.message);
      }
    };

    fetchHtmlContent();
  }, []);

  return (
    <>
      {isDetail ? (
        <BlogDetailArticle
          articles={articles}
          selectedArticle={selectedArticle}
        />
      ) : (
        <Grid
          container
          className="Blog"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} style={{ padding: "30px", textAlign: "center" }}>
            <Typography className="title">
              {" "}
              Il blog di DenunciaOnline
            </Typography>
            <Typography
              style={{ marginTop: "20px" }}
              className="textDescription"
            >
              Tutti le informazioni e le news sul mondo delle denunce e dei
              reati.
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            style={{
              marginTop: "30px",
              marginBottom: "100px",
            }}
          >
            <Grid container justifyContent="center">
              {articles &&
                articles.map((art) => (
                  <CardBlog
                    featured_image={art.featured_image}
                    id={art.id}
                    title={art.title}
                    slug={art.slug}
                    content={art.content}
                    key={art.id}
                  />
                ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BlogContainer;
