import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

const CookiePolicyPartner = (props) => {
  return (
    <>
      <main>
        <section className="p-5 process">
          <div className="container">
            <h2 className="my-5">Cookie Policy</h2>
            <p className="col fs-7" style={{ fontSize: "16px" }}>
              Questo sito web utilizza cookie di tipo tecnico, necessari per il
              funzionamento del sito web. <br /> Denuncia Online S.r.l., titolare
              del trattamento dei dati, informa l’utente che tale sito web
              installa esclusivamente cookie tecnici e non anche cookie non
              tecnici (es. di profilazione), I cookie sono piccoli file di testo
              utilizzati dai siti web per rendere più efficiente l'esperienza
              per l'utente. <br /> La legge afferma che possiamo memorizzare i
              cookie tecnici sul suo dispositivo in quanto sono strettamente
              necessari per il funzionamento di questo sito. Su questo sito web
              non è richiesto nessun permesso dell'utente in quanto non vengono
              utilizzati cookie per i quali la legge richiede un esplicito
              consenso. <br /> Scopra di più su chi siamo, come può contattarci
              e come trattiamo i dati personali nella nostra{" "}
              <a href="/privacy" target="_blanck">
                Informativa sulla privacy
              </a>
              . <br />
              <br />
              Di seguito i cookie tecnici utilizzati: <br />
              <br />
              <ul>
                <li>
                  Cookie HTTPG_ENABLED_IDPS{" "}
                  <a
                    href="https://business.safety.google/privacy/"
                    target="_blanck"
                  >
                    Google
                  </a>{" "}
                  - Utilizzato per effettuare l'accesso sicuro al sito internet
                  con un account Google. Durata max archiviazione: 2913338
                  giorni. <br /> Tipo: Cookie HTTP
                </li>
                <li>
                  Cookie HTTPtest_cookie{" "}
                  <a
                    href="https://www.denunciaonline.eu/cookie-policy"
                    target="_blanck"
                  >
                    Google
                  </a>{" "}
                  - Utilizzato per verificare se il browser dell'utente supporta
                  i cookie. Durata max archiviazione: 1 giorno. Tipo: Cookie
                  HTTP
                </li>
              </ul>
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default CookiePolicyPartner;
