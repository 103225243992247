import { constants } from "./constants";

const initialState = {
  // status: null,
  // userEmail: null,
  // documentId: null,
  // digitalSing: null,
  // totalPrice: null,
  // payed: null,
  // requestStatus: 1,
  customer: null,
  report: null,
  reports: [],
  reportData: null,
};

const initialMessageState = {
  messages: [],
  loading: false,
  error: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.ACTION_REQUEST:
      return {
        ...state,
        errors: null,
        requestStatus: -1,
      };
    case constants.ACTION_SUCCESS:
      return {
        ...state,
        ...action.data.data,
        reportData: action.data,
        requestStatus: 1,
      };
    case constants.ACTION_FAILURE:
      return {
        ...state,
        requestStatus: 0,
        errors: {
          ...action.error.errors,
        },
      };
    // return initialState;

    default:
      return state;
  }
}

export function messageReducer(state = initialMessageState, action) {
  switch (action.type) {
    case constants.FETCH_MESSAGES_REQUEST:
    case constants.SEND_MESSAGE_REQUEST:
    case constants.DOWNLOAD_PDF_MESSAGE_DISCARDED_REQUEST:
    case constants.DOWNLOAD_HISTORY_CHAT_DISCARDED_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case constants.FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.data,
      };

    case constants.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: [...state.messages, action.data],
      };

    case constants.DOWNLOAD_PDF_MESSAGE_DISCARDED_SUCCESS:
      return {
        ...state,
        loading: false,
        pdf: action.data,
      };

    case constants.DOWNLOAD_HISTORY_CHAT_DISCARDED_SUCCESS:
      return {
        ...state,
        loading: false,
        csv: action.data,
      };

    case constants.FETCH_MESSAGES_FAILURE:
    case constants.SEND_MESSAGE_FAILURE:
    case constants.DOWNLOAD_PDF_MESSAGE_DISCARDED_FAILURE:
    case constants.DOWNLOAD_HISTORY_CHAT_DISCARDED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
